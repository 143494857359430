//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-656:_1132,_3892,_6236,_7272,_4131,_8772,_7664,_3440;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-656')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-656', "_1132,_3892,_6236,_7272,_4131,_8772,_7664,_3440");
        }
      }catch (ex) {
        console.error(ex);
      }