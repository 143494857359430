export default {
  SEARCH_PAGE_CLASS: 'container',
  PRODUCT_REPEATER_CLASS: 'product-items product-items-3',
  BUTTON_PRIMARY_CLASS: 'btn btn-default',

  FACET_DIALOG_TITLE: 'Filter Options',
  SELECT_TITLE: 'Select {{ title }}',

  SEARCH_BOX_INPUT_CLASS: 'search-text form-control',
  SEARCH_BOX_BUTTON_CLASS: 'btn btn-default',
};
