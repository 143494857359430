export default {
  platform: 'shift4shop',
  searchPageUrl: '/Search_ep_42-1.html',
  SearchRequestDefaults: {
    pageSize: 30,
  },
  facets: {
    selectFacet: { fields: ['HP', 'Frame'] },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#extrapage',
      template: 'SearchPage',
      visibleIf: {
        selector: '#cm_results',
      },
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#category .category-products',
      template: 'SearchPage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '#searchBox',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name',
        'DateCreated:desc': 'Newest',
        'ReviewAverage:desc': 'Avg Review',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
