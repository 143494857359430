import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { FacetPanel } from 'Components'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
SearchBoxDialogButton,
FacetPanel,
SearchHeader,
SearchResult,
FacetDialog,
SearchBoxDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};