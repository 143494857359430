//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-656:_2780,_9856,_2410,_3468,_3236,_3892,_2480,_4320;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-656')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-656', "_2780,_9856,_2410,_3468,_3236,_3892,_2480,_4320");
        }
      }catch (ex) {
        console.error(ex);
      }